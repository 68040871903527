import remark from "remark";
import remarkHTML from "remark-html";

export const toHTML = (value) =>
  remark()
    .use(remarkHTML)
    .processSync(value)
    .toString();

export function openJoinUsPopup() {
  window.dojoRequire(["mojo/signup-forms/Loader"], function(L) {
    L.start({
      baseUrl: "mc.us19.list-manage.com",
      uuid: "f2bcfc4bf7904b85419f8cf48",
      lid: "eb2bdd6bf0",
      uniqueMethods: true,
    });
    // Workaround to allow the mailchimp popup to be opened multiple times.
    // SOURCE: https://gist.github.com/scottmagdalein/259d878ad46ed6f2cdce
    document.cookie =
      "MCPopupClosed=;path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    document.cookie =
      "MCPopupSubscribed=;path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  });
}

export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
